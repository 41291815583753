import { createApi } from "@reduxjs/toolkit/query/react";
import { ReadSystemsQuery } from "features/systems/domain/models/read-systems-query";
import { ReadSystemsResponse } from "features/systems/domain/models/read-systems-response";
import { System } from "features/systems/domain/models/system";
import { baseQuery } from "redux-base/create-api-utils";

const baseUrl = "/api/v1/System";

export const systemsApi = createApi({
  reducerPath: "systems",
  baseQuery,
  tagTypes: ["Systems"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    readSystems: build.query<ReadSystemsResponse, ReadSystemsQuery>({
      query: (body) => ({
        url: `${baseUrl}/ReadSystems`,
        method: "POST",
        body,
      }),
      providesTags: ["Systems"],
    }),
    readSystem: build.query<System, string>({
      query: (id) => ({
        url: `${baseUrl}/ReadSystem/${id}`,
        method: "GET",
      }),
    }),
    updateSystem: build.mutation<System, System>({
      query: (body) => ({
        url: `${baseUrl}/UpdateSystem`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Systems"],
    }),
    deleteSystem: build.mutation<void, string>({
      query: (id) => ({
        url: `${baseUrl}/DeleteSystem/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Systems"],
    }),
    validateDeleteSystem: build.mutation<void, string>({
      query: (id) => ({
        url: `${baseUrl}/ValidateDeleteSystem/${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyReadSystemsQuery,
  useReadSystemsQuery,
  useLazyReadSystemQuery,
  useUpdateSystemMutation,
  useValidateDeleteSystemMutation,
  useDeleteSystemMutation,
} = systemsApi;
