import React, { ReactElement, ReactNode } from "react";
import useSubscriptionInfo, {
  ISubscriptionInfoHook,
} from "features/subscription-info/context/subscription-info-hook";

const SubscriptionInfoContext = React.createContext<ISubscriptionInfoHook>(
  {} as never,
);

interface IProps {
  children: ReactNode;
}

export const SubscriptionInfoProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <SubscriptionInfoContext.Provider value={useSubscriptionInfo()}>
      {props.children}
    </SubscriptionInfoContext.Provider>
  );
};

export const useSubscriptionInfoContextProvider = (): ISubscriptionInfoHook =>
  React.useContext<ISubscriptionInfoHook>(SubscriptionInfoContext);
