import { Button, Typography } from "@mui/material";
import styles from "features/systems/IS32/views/configuration-components/IS32-location-configuration.module.scss";
import { useTranslation } from "react-i18next";
import Divider from "components/divider/divider.component";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { LinkOff as LinkOffIcon } from "@mui/icons-material";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";
import { useIS32ContextProvider } from "features/systems/IS32/context/IS32-provider";
import { getUnitLocationKey } from "utils/IS32-utils";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";

const IS32LocationConfiguration = (): JSX.Element => {
  const { t } = useTranslation("IS32");
  const { hasPermission } = useAuth();

  const {
    systemsDetailsHook: { viewingMode },
  } = useSystemsContextProvider();
  const { openDeviceDetailsByDeviceUnitLocation } = useIS32ContextProvider();
  const {
    selectedIS32ConfigurationUnit,
    unlinkSubUnitLocation,
    unitLocationHasDevice,
  } = useIS32DetailsContextProvider();

  const getSubUnitLocations = (locationType: string) => {
    const subUnitLocations = Array.from({ length: 8 }, (_, index) => (
      <div
        className={styles.row}
        key={getUnitLocationKey(
          selectedIS32ConfigurationUnit?.address,
          locationType,
          index + 1,
        )}
      >
        <Typography
          className={`${
            viewingMode === "viewing" &&
            unitLocationHasDevice(locationType, index + 1)
              ? styles.clickable
              : ""
          }`}
          variant="subtitle1"
          onClick={() =>
            unitLocationHasDevice(locationType, index + 1) &&
            openDeviceDetailsByDeviceUnitLocation(
              `${selectedIS32ConfigurationUnit?.address}_${locationType}${
                index + 1
              }`,
            )
          }
        >
          {t(`locations.${locationType}`, { index: index + 1 })}
        </Typography>
        {hasPermission(Permission.CreateDevice) &&
          !unitLocationHasDevice(locationType, index + 1) && (
            <Button
              startIcon={
                <LinkOffIcon
                  color="primary"
                  className={
                    viewingMode === "editing" ? styles.disabledIcon : ""
                  }
                />
              }
              variant="text"
              onClick={() => unlinkSubUnitLocation(locationType, index + 1)}
              onFocus={(event) => event.stopPropagation()}
              disabled={viewingMode === "editing"}
              className={styles.unlinkButton}
            >
              {t("actions.unlink")}
            </Button>
          )}
      </div>
    ));

    return <>{subUnitLocations}</>;
  };

  return (
    <div className={styles.container}>
      <Divider className={styles.divider} startText={t("locations.title")} />
      <div className={styles.contentContainer}>
        <Typography
          className={`${styles.row} ${
            viewingMode === "viewing" ? styles.clickable : ""
          }`}
          variant="subtitle1"
          onClick={() =>
            unitLocationHasDevice("room") &&
            openDeviceDetailsByDeviceUnitLocation(
              `${selectedIS32ConfigurationUnit?.address}_room`,
            )
          }
        >
          {t("locations.room")}
        </Typography>
        {getSubUnitLocations("bed")}
        {getSubUnitLocations("toilet")}
      </div>
    </div>
  );
};

export default IS32LocationConfiguration;
