import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { ReadSystemsResponse } from "features/systems/domain/models/read-systems-response";
import { useLazyReadSystemsQuery } from "features/systems/domain/reducers/systems.reducer";
import useSystemDetails, {
  SystemDetailsHook,
} from "features/systems/hooks/system-details-hook";
import useSystemFilters, {
  SystemFiltersHook,
} from "features/systems/hooks/system-filters-hook";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export interface SystemsHook {
  systemsResponse?: ReadSystemsResponse;
  systemsIsLoading: boolean;
  systemsIsSuccess: boolean;

  systemsFilterHook: SystemFiltersHook;
  systemsDetailsHook: SystemDetailsHook;

  openDetailsInViewingMode: (systemId?: string) => void;
  openDetailsInEditingMode: (systemId?: string) => void;
}

const useSystems = (): SystemsHook => {
  const dispatch = useDispatch();
  const systemsFilterHook = useSystemFilters();
  const systemsDetailsHook = useSystemDetails();

  const [
    triggerReadSystems,
    {
      data: systemsResponse,
      error,
      isError,
      isLoading: systemsIsLoading,
      isSuccess: systemsIsSuccess,
    },
  ] = useLazyReadSystemsQuery();

  useEffect(() => {
    triggerReadSystems(systemsFilterHook.readSystemsQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemsFilterHook.readSystemsQuery]);

  useEffect(() => {
    if (isError && error) {
      dispatch(
        setErrorMessage({
          error,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const openDetailsInViewingMode = (systemId?: string) => {
    systemsDetailsHook.openDetailsInViewingMode(systemId);
  };

  const openDetailsInEditingMode = (systemId?: string) => {
    systemsDetailsHook.openDetailsInEditingMode(systemId);
  };

  return {
    systemsResponse,
    systemsIsLoading,
    systemsIsSuccess,

    systemsFilterHook,
    systemsDetailsHook,

    openDetailsInViewingMode,
    openDetailsInEditingMode,
  };
};

export default useSystems;
