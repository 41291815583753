import {
  useLazyReadIS32ConfigurationQuery,
  useLazyScanConfigurationQuery,
} from "features/systems/IS32/domain/reducers/IS32.reducer";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { useEffect, useState } from "react";
import {
  SystemEventStatus,
  SystemEventType,
} from "features/systems/domain/models/system";
import { FinishScanSystemEventResponse } from "features/systems/IS32/domain/models/finish-scan-system-event-response";
import { useConfirmationPopupContextProvider } from "components/provided-confirmation-popup/context/confirmation-popup-provider";
import { useTranslation } from "react-i18next";
import { ReadIS32ConfigurationResponse } from "features/systems/IS32/domain/models/read-IS32-configuration-unit-response";
import { useDevices } from "features/device/devices/hooks";

export interface IS32Hook {
  startScanIsLoading: boolean;

  inProgressPopupIsOpen: boolean;
  succeededPopupIsOpen: boolean;
  failedPopupIsOpen: boolean;

  lastScanSucceededResult: FinishScanSystemEventResponse | undefined;

  closePopup: () => void;

  startScan: (systemId: string) => void;

  readIS32ConfigurationIsLoading: boolean;
  readIS32ConfigurationData: ReadIS32ConfigurationResponse | undefined;

  selectedDeviceId: string | undefined;
  openDeviceDetailsByDeviceUnitLocation: (unitLocation: string) => void;
  openDeviceDetailsByDeviceId: (id: string) => void;
}

const useIS32 = (): IS32Hook => {
  const { t } = useTranslation("IS32");

  const {
    systemsDetailsHook: { selectedSystem, viewingMode, refreshSystem },
  } = useSystemsContextProvider();
  const { showConfirmationPopup } = useConfirmationPopupContextProvider();
  const { openDeviceDetailsPopup } = useDevices();

  const [
    triggerScanConfiguration,
    {
      isFetching: scanIsFetching,
      isLoading: scanIsLoading,
      isSuccess: scanIsSuccess,
    },
  ] = useLazyScanConfigurationQuery();

  const [
    readIS32Configuration,
    {
      isLoading: readIS32ConfigurationIsLoading,
      data: readIS32ConfigurationData,
    },
  ] = useLazyReadIS32ConfigurationQuery();

  const [previousLastSystemEventStatus, setPreviousLastSystemEventStatus] =
    useState<SystemEventStatus>();

  const [inProgressPopupIsOpen, setInProgressPopupIsOpen] =
    useState<boolean>(false);
  const [succeededPopupIsOpen, setSucceededPopupIsOpen] =
    useState<boolean>(false);
  const [failedPopupIsOpen, setFailedPopupIsOpen] = useState<boolean>(false);
  const [lastScanSucceededResult, setLastScanSucceededResult] =
    useState<FinishScanSystemEventResponse>();

  const [selectedDeviceId, setSelectedDeviceId] = useState<
    string | undefined
  >();

  useEffect(() => {
    selectedSystem?.id && readIS32Configuration(selectedSystem?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSystem]);

  useEffect(() => {
    if (!scanIsLoading && !scanIsFetching && scanIsSuccess) {
      refreshSystem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanIsFetching, scanIsLoading, scanIsSuccess]);

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;

    if (
      selectedSystem?.lastSystemEvent?.systemEventStatus ===
      SystemEventStatus.InProgress
    ) {
      intervalId = setInterval(() => {
        refreshSystem();
      }, 10000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [refreshSystem, selectedSystem?.lastSystemEvent?.systemEventStatus]);

  useEffect(() => {
    const currentEventType = selectedSystem?.lastSystemEvent?.systemEventType;
    const currentEventStatus =
      selectedSystem?.lastSystemEvent?.systemEventStatus;

    if (
      currentEventType === SystemEventType.ScanIS32Configuration &&
      previousLastSystemEventStatus !== currentEventStatus
    ) {
      if (currentEventStatus === SystemEventStatus.InProgress) {
        setInProgressPopupIsOpen(true);
        setSucceededPopupIsOpen(false);
        setFailedPopupIsOpen(false);
      } else if (
        previousLastSystemEventStatus === SystemEventStatus.InProgress &&
        currentEventStatus === SystemEventStatus.Succeeded
      ) {
        let numberOfNewDevices = JSON.parse(
          selectedSystem!.lastSystemEvent!.response,
        ).NumberOfNewDevices;

        setLastScanSucceededResult({ numberOfNewDevices });

        setInProgressPopupIsOpen(false);
        setSucceededPopupIsOpen(true);
        setFailedPopupIsOpen(false);
      } else if (
        previousLastSystemEventStatus === SystemEventStatus.InProgress &&
        currentEventStatus === SystemEventStatus.Failed
      ) {
        setInProgressPopupIsOpen(false);
        setSucceededPopupIsOpen(false);
        setFailedPopupIsOpen(true);
      }

      setPreviousLastSystemEventStatus(currentEventStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSystem?.lastSystemEvent?.systemEventStatus,
    previousLastSystemEventStatus,
  ]);

  const startScan = (systemId: string): void => {
    showConfirmationPopup(
      () => triggerScanConfiguration(systemId),
      t("startScanConfirmationPopup.title"),
      t("startScanConfirmationPopup.body"),
      t("startScanConfirmationPopup.primaryActionButtonText"),
      t("startScanConfirmationPopup.secondaryActionButtonText"),
    );
  };

  const closePopup = (): void => {
    setInProgressPopupIsOpen(false);
    setSucceededPopupIsOpen(false);
    setFailedPopupIsOpen(false);
  };

  const openDeviceDetailsByDeviceUnitLocation = (unitLocation: string) => {
    if (viewingMode === "viewing") {
      const externalId = `${selectedSystem!.macAddress}_${unitLocation}`;

      setSelectedDeviceId(
        selectedSystem!.devices?.find((x) => x.externalId === externalId)?.id,
      );
      openDeviceDetailsPopup(false);
    }
  };

  const openDeviceDetailsByDeviceId = (id: string) => {
    if (viewingMode === "viewing") {
      setSelectedDeviceId(id);
      openDeviceDetailsPopup(false);
    }
  };

  return {
    startScan,

    closePopup,

    startScanIsLoading: scanIsLoading,
    inProgressPopupIsOpen,
    failedPopupIsOpen,
    succeededPopupIsOpen,
    lastScanSucceededResult,

    readIS32ConfigurationIsLoading,
    readIS32ConfigurationData,

    selectedDeviceId,
    openDeviceDetailsByDeviceUnitLocation,
    openDeviceDetailsByDeviceId,
  };
};

export default useIS32;
