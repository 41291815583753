import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { useAxiosClientsContextProvider } from "shared/axios/axios-clients-provider";
import { MediaserverSetting } from "features/mediaserver-switch/domain/models/mediaserver-setting";
import { UpdateMediaserverSettingCommand } from "features/mediaserver-switch/domain/models/update-mediaserver-setting-command";

const baseUrl = "/api/v1";

const readMediaserverSetting = async (
  axiosInstance: AxiosInstance,
): Promise<MediaserverSetting> => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/Settings/ReadMediaserverSetting`,
    );

    return response.data.mediaserverSetting as MediaserverSetting;
  } catch (error) {
    throw new Error("Error while calling ReadMediaserverSetting", {
      cause: error,
    });
  }
};

const updateMediaserverSetting = async (
  axiosInstance: AxiosInstance,
  command: UpdateMediaserverSettingCommand,
) => {
  const requestBody = JSON.stringify(command);
  const response = await axiosInstance.put(
    `${baseUrl}/Settings/UpdateMediaserverSetting`,
    requestBody,
  );
  return response.data;
};

export const useReadMediaserverSettingQuery =
  (): UseQueryResult<MediaserverSetting> => {
    const { authenticatedInstance } = useAxiosClientsContextProvider();
    return useQuery({
      queryKey: ["ReadMediaserverSetting"],
      queryFn: () => readMediaserverSetting(authenticatedInstance),
      refetchOnWindowFocus: false,
    });
  };

export const useUpdateMediaserverSettingMutation = () => {
  const { authenticatedInstance } = useAxiosClientsContextProvider();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["ChangeAlarmStatus"],
    mutationFn: (changeAlarmStatusCommand: UpdateMediaserverSettingCommand) =>
      updateMediaserverSetting(authenticatedInstance, changeAlarmStatusCommand),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["ReadMediaserverSetting"] });
    },
  });
};
