import PageTab from "components/page-tab/views/page-tab";
import routes from "features/routing/routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showConfirmationPopup } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";

const SettingsNavigation = () => {
  const { t } = useTranslation("settings");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changePage = (link: string) => {
    dispatch(
      showConfirmationPopup({
        confirmActionNextAction: () => {
          navigate(link);
        },
      }),
    );
  };

  return (
    <PageTab
      onPageChanged={changePage}
      pageTabs={[
        {
          link: routes.settingsExplanationLink.path,
          title: t("pageTab.explanationLabel"),
          atLeastOnePermissionOf:
            routes.settingsExplanationLink.atLeastOnePermissionOf,
        },
        {
          link: routes.settingsEmergencyAlarm.path,
          title: t("pageTab.emergencyAlarmLabel"),
          atLeastOnePermissionOf:
            routes.settingsEmergencyAlarm.atLeastOnePermissionOf,
        },
        {
          link: routes.settingsMalfunctionNotifications.path,
          title: t("pageTab.malfunctionNotificationsLabel"),
          atLeastOnePermissionOf:
            routes.settingsMalfunctionNotifications.atLeastOnePermissionOf,
        },
        {
          link: routes.settingsMediaserverSwitch.path,
          title: t("pageTab.mediaLabel"),
          atLeastOnePermissionOf:
            routes.settingsMediaserverSwitch.atLeastOnePermissionOf,
        },
      ]}
    />
  );
};

export default SettingsNavigation;
