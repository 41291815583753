import { MediaserverSetting } from "features/mediaserver-switch/domain/models/mediaserver-setting";
import {
  useReadMediaserverSettingQuery,
  useUpdateMediaserverSettingMutation,
} from "features/mediaserver-switch/domain/queries/media-queries";

export interface IMediaserverSwitchHook {
  isLoadingMediaserverSetting: boolean;

  mediaserverSetting: MediaserverSetting | undefined;
  setMediaserverSetting: (value: MediaserverSetting) => void;
}

const useMediaserverSwitch = (): IMediaserverSwitchHook => {
  const {
    isLoading: isLoadingMediaserverSetting,
    data: mediaserverSetting,
    isFetching: isFetchingMediaserverSetting,
  } = useReadMediaserverSettingQuery();
  const {
    mutate: executeUpdateMediaserverSetting,
    isPending: isLoadingUpdateMediaserverSetting,
  } = useUpdateMediaserverSettingMutation();

  const setMediaserverSetting = (value: MediaserverSetting) => {
    executeUpdateMediaserverSetting({
      mediaserverSetting: value,
    });
  };

  return {
    isLoadingMediaserverSetting:
      isLoadingMediaserverSetting ||
      isFetchingMediaserverSetting ||
      isLoadingUpdateMediaserverSetting,

    mediaserverSetting,
    setMediaserverSetting,
  };
};

export default useMediaserverSwitch;
