import { useLazyGetSubscriptionInfoQuery } from "features/subscription-info/domain/reducers/subscription-queries";
import { SubscriptionInfo } from "features/subscription-info/domain/models/subscription-info";
import { useEffect, useRef } from "react";
import { useAuth } from "features/authentication/providers/authentication.provider";

export interface ISubscriptionInfoHook {
  subscriptionInfo: SubscriptionInfo | undefined;
}

const useSubscriptionInfo = (): ISubscriptionInfoHook => {
  const { user } = useAuth();
  const userRef = useRef(user);

  const { data: subscriptionInfo, refetch: fetchSubscriptionInfo } =
    useLazyGetSubscriptionInfoQuery();

  useEffect(() => {
    userRef.current = user;

    if (user) {
      fetchSubscriptionInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    subscriptionInfo,
  };
};

export default useSubscriptionInfo;
