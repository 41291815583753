import { Checkbox, Chip, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Paper from "@mui/material/Paper";
import { ReactElement, useState } from "react";
import OutsideAlerter from "components/outside-alerter/outside-alerter";
import "./multi-value-filter-chip.component.scss";
import { useTranslation } from "react-i18next";
import { FilterChipOption } from "./models/filter-chip-option";

interface IProps {
  options: FilterChipOption[];
  onOptionsSelected: (keys: string[]) => void;
  placeHolder: string;
  selectedOptions: FilterChipOption[];
}

export default function MultiValueFilterChip(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("multiValueFilterChip");
  const [isOpen, setIsOpen] = useState(false);

  function handleOnChipClicked() {
    setIsOpen(!isOpen);
  }

  function handleOnCheckboxChanged(option: FilterChipOption) {
    const currentSelectedValues = props.selectedOptions.flatMap(
      (opt) => opt.value,
    );
    const selectedIndex = currentSelectedValues.findIndex((value) =>
      option.value.includes(value),
    );

    let newSelectedValues: string[];
    if (selectedIndex === -1) {
      newSelectedValues = [...currentSelectedValues, ...option.value];
    } else {
      newSelectedValues = currentSelectedValues.filter(
        (value) => !option.value.includes(value),
      );
    }
    props.onOptionsSelected(newSelectedValues);
  }

  const getLabel = (): string =>
    props.selectedOptions.length > 0
      ? props.selectedOptions
          .map((selectedOption) => selectedOption.label)
          .join(", ")
      : props.placeHolder;

  const sortedOptions = [...props.options].sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  return (
    <OutsideAlerter functionToExecute={() => setIsOpen(false)}>
      <div className="multi-value-filter-chip">
        <Chip
          data-testid={`filterChip${props.placeHolder}`}
          label={getLabel()}
          variant="outlined"
          clickable
          deleteIcon={<ArrowDropDownIcon />}
          onDelete={handleOnChipClicked}
          onClick={handleOnChipClicked}
          className={`${props.selectedOptions.length > 0 ? "active" : ""}`}
        />
        {isOpen && (
          <Paper elevation={1} className="options">
            {sortedOptions.length === 0 ? (
              <Typography className="no-filter-options">
                {t("noFilterOptions")}
              </Typography>
            ) : (
              sortedOptions.map((option) => {
                const isSelected = props.selectedOptions.some(
                  (o) => o.key === option.key,
                );
                return (
                  <MenuItem
                    data-testid={`filterOption${option.key.replace(
                      /\s+/g,
                      "_",
                    )}`}
                    onClick={() => handleOnCheckboxChanged(option)}
                    key={option.key}
                    sx={{ padding: "0px 4px" }}
                  >
                    <Checkbox
                      sx={{
                        padding: 0,
                        margin: "8px",
                      }}
                      size="small"
                      onChange={() => handleOnCheckboxChanged(option)}
                      checked={isSelected}
                      key={option.key}
                    />
                    {option.label}
                  </MenuItem>
                );
              })
            )}
          </Paper>
        )}
      </div>
    </OutsideAlerter>
  );
}
