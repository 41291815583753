import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { useAxiosClientsContextProvider } from "shared/axios/axios-clients-provider";
import { SubscriptionInfo } from "features/subscription-info/domain/models/subscription-info";

const baseUrl = "/api/v6/Subscription";

const getSubscriptionInfo = async (
  axiosInstance: AxiosInstance,
): Promise<SubscriptionInfo> => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/GetSubscriptionInfo?subscriptionDeviceType=Portal`,
    );

    return response.data as SubscriptionInfo;
  } catch (error) {
    throw new Error("Error while calling GetSubscriptionInfo", {
      cause: error,
    });
  }
};

export const useLazyGetSubscriptionInfoQuery =
  (): UseQueryResult<SubscriptionInfo> => {
    const { authenticatedInstance } = useAxiosClientsContextProvider();
    return useQuery({
      queryKey: ["GetSubscriptionInfo"],
      queryFn: () => getSubscriptionInfo(authenticatedInstance),
      refetchOnWindowFocus: true,
      enabled: false,
    });
  };
