export const getUnitLocationDeviceExternalId = (
  macAddress: string,
  address: string,
  unitLocation: string,
  number?: number,
): string => {
  return `${macAddress}_${address}_${unitLocation}${number ?? ""}`;
};

export const getUnitLocationKey = (
  address: string | undefined,
  locationType: string,
  number?: number,
): string => {
  return `${address}_${locationType}_${number ?? ""}`;
};
