import { useTranslation } from "react-i18next";
import PopUp from "components/pop-up/pop-up.component";
import Device from "features/device/domain/models/device";
import { useDeviceDeleteHook } from "features/device/hooks/device-delete-hook";

interface IProps {
  device: Device | undefined;
  handleOnClose: () => void;
}

const DeviceDeletePopup = ({ device, handleOnClose }: IProps): JSX.Element => {
  const { t } = useTranslation("devices");

  const {
    isDeleteConfirmationPopupOpen,
    isDeleteErrorPopupOpen,
    closeDeleteConfirmationPopup,
    closeDeleteErrorPopup,
    deleteDevice,
  } = useDeviceDeleteHook(device, handleOnClose);

  return (
    <>
      <PopUp
        isOpen={isDeleteConfirmationPopupOpen}
        title={t("deleteDeviceConfirmation.title", {
          deviceName: device?.name,
        })}
        body={t("deleteDeviceConfirmation.bodyText")}
        primaryButtonText={t("deleteDeviceConfirmation.confirmButton")}
        secondaryButtonText={t("deleteDeviceConfirmation.cancelButton")}
        handleOnClose={() => {
          closeDeleteConfirmationPopup();
          handleOnClose();
        }}
        secondaryButtonAction={() => {
          closeDeleteConfirmationPopup();
          handleOnClose();
        }}
        primaryButtonAction={() => {
          deleteDevice();
        }}
      />

      <PopUp
        isOpen={isDeleteErrorPopupOpen}
        title={t("deleteDeviceError.title", {
          deviceName: device?.name,
        })}
        body={t("deleteDeviceError.bodyText")}
        primaryButtonText={t("deleteDeviceError.confirmButton")}
        handleOnClose={() => {
          closeDeleteErrorPopup();
          handleOnClose();
        }}
        primaryButtonAction={() => {
          closeDeleteErrorPopup();
          handleOnClose();
        }}
      />
    </>
  );
};

export default DeviceDeletePopup;
