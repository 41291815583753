import React, { ReactElement, ReactNode } from "react";
import useMediaserverSwitch, {
  IMediaserverSwitchHook,
} from "features/mediaserver-switch/context/mediaserver-switch-hook";

const MediaserverSwitchContext = React.createContext<IMediaserverSwitchHook>(
  {} as never,
);

interface IProps {
  children: ReactNode;
}

export const MediaserverSwitchProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <MediaserverSwitchContext.Provider value={useMediaserverSwitch()}>
      {props.children}
    </MediaserverSwitchContext.Provider>
  );
};

export const useMediaserverSwitchContextProvider = (): IMediaserverSwitchHook =>
  React.useContext<IMediaserverSwitchHook>(MediaserverSwitchContext);
