enum PermissionGroup {
  App = "App",
  AppMonitoring = "AppMonitoring",
  Dashboard = "Dashboard",
  Integrations = "Integrations",
  History = "History",
  User = "User",
  OrganisationUnit = "OrganisationUnit",
  ResidentsSettings = "ResidentsSettings",
  Scenario = "Scenario",
  TemporaryAccess = "TemporaryAccess",
  Settings = "Settings",
  CentralSupportPortal = "CentralSupportPortal",
  CentralSupportMonitoring = "CentralSupportMonitoring",
}

export default PermissionGroup;
