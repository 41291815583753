import { useAuth } from "features/authentication/providers/authentication.provider";
import routes from "features/routing/routes";
import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RedirectToSettingsDetails(): ReactElement {
  const { hasAtLeastOnePermissionOf, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      hasAtLeastOnePermissionOf(
        routes.settingsExplanationLink.atLeastOnePermissionOf,
      )
    ) {
      navigate(routes.settingsExplanationLink.path, { replace: true });
    } else if (
      hasAtLeastOnePermissionOf(
        routes.settingsEmergencyAlarm.atLeastOnePermissionOf,
      )
    ) {
      navigate(routes.settingsEmergencyAlarm.path, { replace: true });
    } else if (
      hasAtLeastOnePermissionOf(
        routes.settingsMediaserverSwitch.atLeastOnePermissionOf,
      )
    ) {
      navigate(routes.settingsMediaserverSwitch.path, { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  }, [user, hasAtLeastOnePermissionOf, navigate]);

  return <></>;
}
