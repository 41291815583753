import { ReactElement } from "react";
import "./temporary-access-filters.scss";
import FilterChip from "components/filter-chip/filter-chip.component";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@mui/material";
import Constants from "style/constants";
import { TemporaryAccessType } from "../domain/models/temporary-access-type";
import { TemporaryAccesReasonType } from "../domain/models/temporary-access-reason-type";
import { TemporaryAccesStatus } from "../domain/models/temporary-access-status";
import { RefreshRounded } from "@mui/icons-material";
import SearchBar from "components/search/searchbar";
import MultiValueFilterChip from "components/multi-value-filter-chip/multi-value-filter-chip.component";
import { useTemporaryAccessContextProvider } from "../providers/temporary-access-provider";
import { FilterValueType } from "../domain/models/temporary-access-filter-value";
import FilterChipDateTime from "components/filter-chip/filter-chip-date-time.component";
import { FilterChipOption } from "components/multi-value-filter-chip/models/filter-chip-option";
import KeyValuePair from "models/key-value-pair";
import OrganisationUnit from "features/organisation/domain/models/organisation-unit";

function TemporaryAccessFilters(): ReactElement {
  const { t } = useTranslation("temporaryAccess");
  const {
    refresh,
    temporaryAccessFilterHook,
    readOrganisationUnitsForTemporaryAccessData,
    readOrganisationUnitsForTemporaryAccessIsSuccess,
  } = useTemporaryAccessContextProvider();

  const typeFilterValues: KeyValuePair[] = [
    {
      key: TemporaryAccessType.User,
      value: t("filterValues.type.user"),
    },
    {
      key: TemporaryAccessType.Location,
      value: t("filterValues.type.location"),
    },
  ];

  const reasonFilterValues: FilterChipOption[] = [
    {
      key: TemporaryAccesReasonType.Vacation,
      label: t("filterValues.reasonType.vacation"),
      value: [TemporaryAccesReasonType.Vacation],
    },
    {
      key: TemporaryAccesReasonType.Sickness,
      label: t("filterValues.reasonType.sickness"),
      value: [TemporaryAccesReasonType.Sickness],
    },
    {
      key: TemporaryAccesReasonType.Calamity,
      label: t("filterValues.reasonType.calamity"),
      value: [TemporaryAccesReasonType.Calamity],
    },
    {
      key: TemporaryAccesReasonType.Other,
      label: t("filterValues.reasonType.other"),
      value: [TemporaryAccesReasonType.Other],
    },
  ];

  const statusFilterValues: FilterChipOption[] = [
    {
      key: TemporaryAccesStatus.Active,
      label: t("filterValues.status.active"),
      value: [TemporaryAccesStatus.Active],
    },
    {
      key: TemporaryAccesStatus.Expired,
      label: t("filterValues.status.expired"),
      value: [TemporaryAccesStatus.Expired],
    },
    {
      key: TemporaryAccesStatus.Revoked,
      label: t("filterValues.status.revoked"),
      value: [TemporaryAccesStatus.Revoked],
    },
  ];

  const mapOrganisationUnitToFilterChipOptions = (
    organisationUnits: OrganisationUnit[],
  ): FilterChipOption[] => {
    return organisationUnits.map(
      (organisationUnit): FilterChipOption => ({
        key: organisationUnit.id,
        value: [organisationUnit.id],
        label: organisationUnit.name,
      }),
    );
  };

  return (
    <div className="temporary-access-filters-container">
      <div className="temporary-access-filters">
        <SearchBar
          onSearchValueChanged={temporaryAccessFilterHook.submitSearch}
          value={temporaryAccessFilterHook.searchBarValue}
        />
        <FilterChip
          key={`temporary-access-filter-chip-type`}
          options={typeFilterValues}
          onOptionSelected={(key?: string) =>
            temporaryAccessFilterHook.selectOption(
              FilterValueType.Type,
              key ? [key] : [],
            )
          }
          placeHolder={t("temporaryAccessTable.column.type")}
          initialSelectedOption={typeFilterValues.find((x) =>
            temporaryAccessFilterHook.activeFilters
              .find((x) => x.filterValueType === FilterValueType.Type)
              ?.values.includes(x.key),
          )}
        />

        {readOrganisationUnitsForTemporaryAccessIsSuccess &&
          readOrganisationUnitsForTemporaryAccessData && (
            <MultiValueFilterChip
              key={`temporary-access-filter-chip-Organisation-unit`}
              options={mapOrganisationUnitToFilterChipOptions(
                readOrganisationUnitsForTemporaryAccessData,
              )}
              onOptionsSelected={(keys: string[]) => {
                temporaryAccessFilterHook.selectOption(
                  FilterValueType.OrganisationUnit,
                  keys,
                );
              }}
              placeHolder={t("filters.location")}
              selectedOptions={mapOrganisationUnitToFilterChipOptions(
                readOrganisationUnitsForTemporaryAccessData,
              ).filter((x) =>
                temporaryAccessFilterHook.activeFilters
                  .find(
                    (x) =>
                      x.filterValueType === FilterValueType.OrganisationUnit,
                  )
                  ?.values.includes(x.key),
              )}
            />
          )}

        <FilterChipDateTime
          key={"temporary-access-filter-chip-period"}
          placeHolder={t("filters.period")}
          currentDateFilters={temporaryAccessFilterHook.dateFilter}
          onDateFiltersChanged={(newDateFilters) =>
            temporaryAccessFilterHook.setDateFilter(newDateFilters)
          }
          includeTime={false}
          onlyHistory={false}
        />

        <MultiValueFilterChip
          key={`temporary-access-filter-chip-reason`}
          options={reasonFilterValues}
          onOptionsSelected={(keys: string[]) =>
            temporaryAccessFilterHook.selectOption(FilterValueType.Reason, keys)
          }
          placeHolder={t("temporaryAccessTable.column.reason")}
          selectedOptions={reasonFilterValues.filter((x) =>
            temporaryAccessFilterHook.activeFilters
              .find((x) => x.filterValueType === FilterValueType.Reason)
              ?.values.includes(x.key),
          )}
        />

        <MultiValueFilterChip
          key={`temporary-access-filter-chip-status`}
          options={statusFilterValues}
          onOptionsSelected={(keys: string[]) =>
            temporaryAccessFilterHook.selectOption(FilterValueType.Status, keys)
          }
          placeHolder={t("filters.status")}
          selectedOptions={statusFilterValues.filter((x) =>
            temporaryAccessFilterHook.activeFilters
              .find((x) => x.filterValueType === FilterValueType.Status)
              ?.values.includes(x.key),
          )}
        />

        <div className="clear-filters">
          <Typography
            variant="body1"
            color={Constants.Colors.primary}
            onClick={temporaryAccessFilterHook.clearFilters}
          >
            {t("filters.clearFilters")}
          </Typography>
        </div>
      </div>

      {refresh && (
        <IconButton className="refresh-button" onClick={refresh}>
          <RefreshRounded />
        </IconButton>
      )}
    </div>
  );
}

export default TemporaryAccessFilters;
