import { useState } from "react";

export interface IDevicesState {
  isPopupOpen: boolean;
  allowUnlinking: boolean;
  openPopup: (allowUnlinking: boolean) => void;
  closePopup: () => void;
}

export const useDevicesState = (): IDevicesState => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [allowUnlinking, setAllowUnlinking] = useState<boolean>(false);

  const openPopup = (allowUnlinking: boolean = false) => {
    setAllowUnlinking(allowUnlinking);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setAllowUnlinking(false);
  };

  return {
    isPopupOpen,
    allowUnlinking,
    openPopup,
    closePopup,
  };
};
