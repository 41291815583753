import { Close } from "@mui/icons-material";
import CheckRounded from "@mui/icons-material/CheckRounded";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton } from "@mui/material";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import styles from "features/systems/views/details/system-details-popup-header.module.scss";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const SystemDetailsPopupHeader = (): JSX.Element => {
  const { t } = useTranslation("systems");

  const { formState } = useFormContext();
  const { systemsDetailsHook } = useSystemsContextProvider();
  const {
    cancelMutation,
    mutationIsLoading,
    deleteSystem,
    closeDetails,
    openDetailsInEditingMode,
    selectedSystem,
    viewingMode,
  } = systemsDetailsHook;

  const isViewing = viewingMode === "viewing";
  const isEditing = viewingMode === "editing";

  return (
    <div className={styles.container}>
      {isEditing && (
        <>
          <Button
            variant="text"
            onClick={cancelMutation}
            disabled={mutationIsLoading}
          >
            {t("details.action.cancel")}
          </Button>
          <LoadingButton
            startIcon={<CheckRounded />}
            variant="contained"
            type="submit"
            loading={mutationIsLoading}
            loadingPosition="start"
          >
            {t("details.action.save")}
          </LoadingButton>
        </>
      )}
      {isViewing && (
        <>
          <AutorisationWrapper
            atLeastOnePermissionOf={[
              Permission.DeleteSystemsGatewaysConnectors,
            ]}
          >
            <Button
              startIcon={<DeleteForeverOutlinedIcon />}
              variant="text"
              onClick={() => deleteSystem(selectedSystem!)}
              disabled={mutationIsLoading}
            >
              {t("details.action.remove")}
            </Button>
          </AutorisationWrapper>
          <AutorisationWrapper
            atLeastOnePermissionOf={[
              Permission.UpdateSystemsGatewaysConnectors,
            ]}
          >
            <Button
              startIcon={<ModeEditOutlineOutlinedIcon />}
              variant="text"
              onClick={() => openDetailsInEditingMode(selectedSystem?.id)}
              disabled={mutationIsLoading}
            >
              {t("details.action.edit")}
            </Button>
          </AutorisationWrapper>
        </>
      )}

      <IconButton
        aria-label="close"
        className={styles.closeButton}
        onClick={() => closeDetails(formState.isDirty)}
      >
        <Close />
      </IconButton>
    </div>
  );
};

export default SystemDetailsPopupHeader;
