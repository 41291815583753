import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styles from "features/mediaserver-switch/views/mediaserver-switch.module.scss";
import PageHeader from "components/page-header/page-header";
import SettingsNavigation from "features/settings/views/settings-navigation";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useMediaserverSwitchContextProvider } from "features/mediaserver-switch/context/mediaserver-switch-provider";
import { MediaserverSetting } from "features/mediaserver-switch/domain/models/mediaserver-setting";

function MediaserverSwitch(): ReactElement {
  const { t } = useTranslation("mediaserverSwitch");

  const { mediaserverSetting, setMediaserverSetting } =
    useMediaserverSwitchContextProvider();

  const handleChange = (event: SelectChangeEvent) => {
    setMediaserverSetting(event.target.value as MediaserverSetting);
  };

  return (
    <>
      <PageHeader
        title={t("pageHeaderTitle")}
        navigationComponent={<SettingsNavigation />}
      />
      {mediaserverSetting && (
        <div className={styles.mediaserverSwitchContainer}>
          <FormControl>
            <InputLabel id="media-server-label">
              {t("mediaserverLabel")}
            </InputLabel>
            <Select
              labelId="media-server-label"
              id="media-server-select"
              value={mediaserverSetting}
              label={t("mediaserverLabel")}
              onChange={handleChange}
              sx={{ width: 500 }}
            >
              <MenuItem value={MediaserverSetting.MediaserverDisabled}>
                {t("mediaserverDisabledText")}
              </MenuItem>
              <MenuItem value={MediaserverSetting.MediaserverEnabled}>
                {t("mediaserverEnabledText")}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </>
  );
}

export default MediaserverSwitch;
