import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import { ReadIS32ConfigurationResponse } from "../models/read-IS32-configuration-unit-response";
import { IS32ConfigurationUnit } from "features/systems/IS32/domain/models/IS32-configuration-unit";
import UpdateIS32ConfigurationCommand from "features/systems/IS32/domain/models/update-IS32-configuration-command";
import CreateIS32LocationDeviceCommand from "features/systems/IS32/domain/models/create-IS32-location-device-command";
import CreateIS32LocationDeviceResponse from "features/systems/IS32/domain/models/create-IS32-location-device-response";

const baseUrl = "/api/v1/ISThirtyTwo";

export const IS32Api = createApi({
  reducerPath: "IS32",
  baseQuery,
  refetchOnMountOrArgChange: false,
  endpoints: (build) => ({
    scanConfiguration: build.query<void, string>({
      query: (systemId: string) => ({
        url: `${baseUrl}/ScanConfiguration/${systemId}`,
        method: "GET",
      }),
    }),
    readIS32Configuration: build.query<ReadIS32ConfigurationResponse, string>({
      query: (systemId: string) => ({
        url: `${baseUrl}/ReadISThirtyTwoConfiguration/${systemId}`,
        method: "GET",
      }),
    }),
    updateIS32Configuration: build.mutation<
      IS32ConfigurationUnit,
      UpdateIS32ConfigurationCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/UpdateISThirtyTwoConfiguration`,
        method: "PUT",
        body,
      }),
    }),
    createIS32LocationDevice: build.mutation<
      CreateIS32LocationDeviceResponse,
      CreateIS32LocationDeviceCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/CreateISThirthyTwoLocationDevice`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyScanConfigurationQuery,
  useLazyReadIS32ConfigurationQuery,
  useUpdateIS32ConfigurationMutation,
  useCreateIS32LocationDeviceMutation,
} = IS32Api;
