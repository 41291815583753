export enum DeviceCommandType {
  Unknown = "Unknown",
  Reset = "Reset",
  Audio = "Audio",
  Video = "Video",
  OpenDoor = "OpenDoor",
  AudioRecording = "AudioRecording",
  Image = "Image",
  Rtsp = "Rtsp",
}
