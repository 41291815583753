import Device from "features/device/domain/models/device";
import { useDeleteDeviceMutation } from "features/device/domain/reducers/device.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-base/store";
import { useDevices } from "features/device/devices/hooks";

export interface IDeviceDeleteHook {
  deleteDevice: () => void;
  closeDeleteConfirmationPopup: () => void;
  closeDeleteErrorPopup: () => void;
  isDeleteConfirmationPopupOpen: boolean;
  isDeleteErrorPopupOpen: boolean;
}

export const useDeviceDeleteHook = (
  device: Device | undefined,
  deleteCallbackAction: () => void,
): IDeviceDeleteHook => {
  const dispatch = useAppDispatch();

  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] =
    useState<boolean>(false);
  const [isDeleteErrorPopupOpen, setIsDeleteErrorPopupOpen] =
    useState<boolean>(false);

  const { closeDeviceDetailsPopup } = useDevices();

  const [currentDevice, setCurrentDevice] = useState<Device>();

  const [
    deleteDeviceMutation,
    {
      isSuccess: deleteDeviceIsSuccess,
      isError: deleteDeviceIsError,
      error: deleteDeviceError,
    },
  ] = useDeleteDeviceMutation();

  useEffect(() => {
    if (deleteDeviceIsSuccess) {
      setIsDeleteConfirmationPopupOpen(false);
      closeDeviceDetailsPopup();
      deleteCallbackAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDeviceIsSuccess]);

  useEffect(() => {
    if (deleteDeviceIsError && deleteDeviceError) {
      dispatch(
        setErrorMessage({
          error: deleteDeviceError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDeviceIsError]);

  useEffect(() => {
    if (device?.organisationUnitId) {
      setIsDeleteErrorPopupOpen(true);
    } else {
      setIsDeleteConfirmationPopupOpen(true);
      setCurrentDevice(device);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDeleteConfirmationPopup = () => {
    setIsDeleteConfirmationPopupOpen(false);
  };

  const closeDeleteErrorPopup = () => {
    setIsDeleteErrorPopupOpen(false);
  };

  const deleteDevice = () => {
    deleteDeviceMutation(currentDevice?.id!);
  };

  return {
    closeDeleteConfirmationPopup,
    closeDeleteErrorPopup,
    deleteDevice,
    isDeleteConfirmationPopupOpen,
    isDeleteErrorPopupOpen,
  };
};
