import { useDevicesContextProvider } from "features/device/devices/providers/devices-provider/devices.provider";
import Device from "features/device/domain/models/device";
import DeviceFilterValues from "features/device/models/device-filter-values";
import ObjectSort from "models/object-sort";
import { useSessionStorage } from "usehooks-ts";

export interface IDevicesHook {
  openDeviceDetailsPopup: (isOpenedFromOrganisationUnit: boolean) => void;
  closeDeviceDetailsPopup: () => void;
  isDeviceDetailsPopupOpen: boolean;
  isDeviceDetailsPopupOpenedFromOrganisationDetailView: boolean;
  deviceFiltersFromSessionStorage: DeviceFilterValues;
  setDeviceFiltersFromSessionStorage: (filters: DeviceFilterValues) => void;
  sortFromSessionStorage: ObjectSort<Device>;
  setSortFromSessionStorage: (sort: ObjectSort<Device>) => void;
  searchBarFromSessionStorage: string;
  setSearchBarFromSessionStorage: (searchBar: string) => void;
}

export const useDevices = (): IDevicesHook => {
  const devicesFilterKey = "devices-filters-storage";
  const sessionStorageSortingKey = "devices-sorting";
  const searchBarSessionStorageKey = "devices-searchbar";

  const [deviceFiltersFromSessionStorage, setDeviceFiltersFromSessionStorage] =
    useSessionStorage<DeviceFilterValues>(devicesFilterKey, {
      filterValues: [],
      monitoringFilterValues: [],
    });
  const [sortFromSessionStorage, setSortFromSessionStorage] = useSessionStorage<
    ObjectSort<Device>
  >(sessionStorageSortingKey, { property: "name", isAscending: true });
  const [searchBarFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>(searchBarSessionStorageKey, "");

  const {
    devicesState: { openPopup, closePopup, isPopupOpen, allowUnlinking },
  } = useDevicesContextProvider();

  const openDeviceDetailsPopup = (isOpenedFromOrganisationUnit: boolean) =>
    openPopup(!isOpenedFromOrganisationUnit);

  const closeDeviceDetailsPopup = () => closePopup();

  return {
    openDeviceDetailsPopup,
    closeDeviceDetailsPopup,
    isDeviceDetailsPopupOpen: isPopupOpen,
    isDeviceDetailsPopupOpenedFromOrganisationDetailView: allowUnlinking,
    deviceFiltersFromSessionStorage,
    setDeviceFiltersFromSessionStorage,
    sortFromSessionStorage,
    setSortFromSessionStorage,
    searchBarFromSessionStorage,
    setSearchBarFromSessionStorage,
  };
};
