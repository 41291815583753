import {
  AccountTreeOutlined,
  DashboardOutlined,
  GroupAddOutlined,
  HistoryRounded,
  MobileFriendlyOutlined,
  PersonPinOutlined,
  SettingsOutlined,
  WarningAmberRounded,
} from "@mui/icons-material";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import MonitorOutlinedIcon from "@mui/icons-material/MonitorOutlined";
import ConversionPathIcon from "components/custom-icons/conversion-path-icon";
import IntegrationsIcon from "components/custom-icons/integrations-icon";
import Permission from "features/autorisation/domain/models/permission";
import PermissionGroup from "features/autorisation/domain/models/permission-group";
import { ReactElement } from "react";

export type PermissionGroupSetting = {
  permissionGroup: PermissionGroup;
  permissions: Array<Permission>;
  icon: ReactElement;
};

export const DefaultPermissionGroupSettingsApp: Array<PermissionGroupSetting> =
  [
    {
      permissionGroup: PermissionGroup.App,
      permissions: [
        Permission.AppAccess,
        Permission.ReadOverview,
        Permission.MuteApp,
        Permission.UserPreferences,
        Permission.RequestTemporaryChannelAccess,
      ],
      icon: <MobileFriendlyOutlined />,
    },
    {
      permissionGroup: PermissionGroup.AppMonitoring,
      permissions: [
        Permission.ReadFaults,
        Permission.ReadFaultsOnUnlinkedDevices,
      ],
      icon: <WarningAmberRounded />,
    },
  ];

export const DefaultPermissionGroupSettingsPortal: Array<PermissionGroupSetting> =
  [
    {
      permissionGroup: PermissionGroup.ResidentsSettings,
      permissions: [
        Permission.ReadResidentsSettings,
        Permission.UpdateResidentsSettings,
      ],
      icon: <PersonPinOutlined />,
    },
    {
      permissionGroup: PermissionGroup.TemporaryAccess,
      permissions: [Permission.ManageTemporaryAccess],
      icon: <GroupAddOutlined />,
    },
    {
      permissionGroup: PermissionGroup.History,
      permissions: [Permission.ReadHistory, Permission.ReadHistoryDetails],
      icon: <HistoryRounded />,
    },
    {
      permissionGroup: PermissionGroup.Dashboard,
      permissions: [Permission.DashboardAlarms, Permission.DashboardResidents],
      icon: <DashboardOutlined />,
    },
    {
      permissionGroup: PermissionGroup.Scenario,
      permissions: [Permission.LinkScenario],
      icon: <ConversionPathIcon />,
    },
    {
      permissionGroup: PermissionGroup.User,
      permissions: [
        Permission.CreateRole,
        Permission.DeleteRole,
        Permission.ReadRole,
        Permission.UpdateRole,
      ],
      icon: <BadgeOutlinedIcon />,
    },
    {
      permissionGroup: PermissionGroup.OrganisationUnit,
      permissions: [
        Permission.CreateOrganisationUnit,
        Permission.DeleteOrganisationUnit,
        Permission.ReadOrganisationUnit,
        Permission.UpdateOrganisationUnit,
      ],
      icon: <AccountTreeOutlined />,
    },
    {
      permissionGroup: PermissionGroup.Integrations,
      permissions: [
        Permission.CreateDevice,
        Permission.ReadDevice,
        Permission.UpdateDevice,
        Permission.DeleteDevice,
        Permission.LinkDevice,
        Permission.CreateSystemsGatewaysConnectors,
        Permission.ReadSystemsGatewaysConnectors,
        Permission.UpdateSystemsGatewaysConnectors,
        Permission.DeleteSystemsGatewaysConnectors,
        Permission.SendTestEvent,
      ],
      icon: <IntegrationsIcon />,
    },
    {
      permissionGroup: PermissionGroup.Settings,
      permissions: [
        Permission.SettingsExplanationLink,
        Permission.SettingsEmergencyAlarm,
        Permission.SettingsMalfunctionNotifications,
      ],
      icon: <SettingsOutlined />,
    },
  ];

export const DefaultPermissionGroupSettingsCentralSupportPortal: Array<PermissionGroupSetting> =
  [
    {
      permissionGroup: PermissionGroup.CentralSupportPortal,
      permissions: [Permission.CentralSupportPortalAccess],
      icon: <MonitorOutlinedIcon />,
    },
    {
      permissionGroup: PermissionGroup.CentralSupportMonitoring,
      permissions: [Permission.CentralSupportReadFaults],
      icon: <WarningAmberRounded />,
    },
  ];
